import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { I18n, Translate } from 'react-redux-i18n';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import message from '../../../plugins/utils/message';
import { RootState, useStateSelector } from '../../../store/reducers';
import { IStudentPhoto } from '../../../models/estudiante_foto_model';

type ModalRejectProps = {
  student: IStudentPhoto;
  loading: boolean;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (
    action: 'reject' | 'approve',
    data: {
      motivoRejection: string;
      idStudent: string;
      idSolicitud: string;
    },
  ) => void;
};

export const useStylesModalPhotoStudents = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: '16px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
    },
    btnSave: {
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
  }),
);

const ModalReject: React.FC<ModalRejectProps> = ({
  loading,
  student,
  handleClose,
  isModalOpen,
  handleSave,
}) => {
  const classes = useStylesModalPhotoStudents();

  const { handleSubmit, control, formState } = useForm<{
    description: string;
    idStudent: string;
  }>({
    shouldUnregister: false,
    defaultValues: {
      description: '',
      idStudent: student.idEstudiante,
    },
    mode: 'all',
  });

  const { locale } = useStateSelector((state: RootState) => state.i18n);

  const onSubmit = (data: { description: string }) => {
    if (data.description.trim().length === 0) {
      const messageError =
        locale === 'es'
          ? 'Debe ingresar un motivo de rechazo valido'
          : 'Bitte geben Sie eine gültige Beschreibung ein';
      message.warning(messageError);
      return;
    }

    handleSave('reject', {
      motivoRejection: data.description,
      idStudent: student.idEstudiante,
      idSolicitud: student.id,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Translate value="studentPhotos.rechazoTitle" />
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box m={1}>
            <Box
              style={{
                width: '100%',
                height: '100%',
                paddingBottom: '20px',
              }}
            >
              <Typography gutterBottom style={{ textAlign: 'center' }}>
                <Translate value="studentPhotos.descriptionReject" />
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s.,\-_¿?¡!()]{1,500}$/,
                      message: I18n.t('form.noCaracteresEspeciales'),
                    },
                    maxLength: {
                      value: 200,
                      message: locale === 'es' ? 'Máximo 200 caracteres' : 'Maximal 200 Zeichen',
                    },
                    required: I18n.t('form.required'),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        type="textarea"
                        multiline
                        rows={3}
                        rowsMax={7}
                        inputProps={{ step: 1, min: 0, max: 6 }}
                        fullWidth
                        variant="filled"
                        required
                        label={I18n.t('form.descripcion')}
                        error={!!error}
                      />
                      {!!error && (
                        <FormHelperText error>{error?.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {loading && (
            <Box position="absolute" left={0} right={0}>
              <LinearProgress color="primary" />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type={'button'}
            disabled={loading}
            onClick={handleClose}
            className={classes.btnError}
            fullWidth
          >
            <Translate value="form.cancelar" />
          </Button>
          <Button
            disabled={loading || !formState.isValid}
            type="submit"
            className={classes.btnSave}
            fullWidth
          >
            <Typography variant="body2" noWrap>
              <Translate value="enviar" />
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalReject;
